import { map, find, mapValues, toLower, trim } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  ArrowIcon, H2, Row, Col, Label, Input, LabeledInput, LabeledSelect, LabeledCheckbox, Toggle, ToggleLink, colors,
  Button
} from '@commonsku/styles';

import { getTagOptions, getAccountStatusOptions, getBenefitsOptions } from '../../selectors/dropdowns';
import { useHasCapabilities } from '../../hooks';

const SupplierTitle = styled(H2)`
  &&& {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    margin-bottom: 0px;
  }
`;

const KeywordsInput = ({ showAdvancedSearch, onToggleSearchClick, value, onChange }) => {
  const height = '2.4375rem';
  const iconStyles = {
    height,
    lineHeight: height,
    cursor: 'pointer',
    borderRadius: 3,
    textAlign: 'center',
    position: 'absolute',
    fontSize: '1em',
  };
  return <Col style={{ position: 'relative' }} xl={6} lg={6} md={8} sm={12} xs={12}>
    <div className="search-box" style={{
      display: 'inline-block', paddingTop: 15, width: '100%',
      background: colors.teal['20'],
      padding: 13,
      borderRadius: showAdvancedSearch ? '8px 8px 0 0' : 8
    }}>
      <i className="fi-magnifying-glass search-icon" style={{
        ...iconStyles,
        color: 'rgb(194, 219, 226)',
        width: height,
      }} />
      <div
        style={{
          ...iconStyles,
          color: colors.teal['65'],
          right: '2rem',
        }}
        onClick={onToggleSearchClick}
      >{showAdvancedSearch ? 'Simple Search' : 'Advanced Search'}</div>
      <LabeledInput
        style={{ paddingLeft: height, fontWeight: 400, marginBottom: 0 }}
        type='text'
        placeholder='Search by supplier name'
        value={value}
        onChange={onChange}
      />
    </div>
  </Col>;
};

const SuppliersSearch = ({ style, onChange, version = 'v1', onCreateAddSupplierPopup }) => {
  const hasCreateUpstreamAccount = useHasCapabilities(['CREATE-UPSTREAM-ACCOUNT']);
  const { tags, accountStatuses, benefits } = useSelector((state) => {
    return mapValues(
      {
        tags: [{ key: "", value: "All" }].concat(getTagOptions(state)),
        accountStatuses: [{ key: "", value: "All" }].concat(
          getAccountStatusOptions(state, { company_type: "SUPPLIER" })
        ),
        benefits: [{ key: "", value: "All" }].concat(getBenefitsOptions(state)),
      },
      (options) => {
        return map(options, ({ key, value }) => {
          return { value: key, label: value };
        });
      },
    );
  });
  const initialFilters = {
    keyword: '', tagId: '', benefitId: '', accountStatusId: '', accountsOnly: false, showAdvancedSearch: false
  };
  const [filters, setFilters] = useState(initialFilters);
  const { keyword, tagId, benefitId, accountStatusId, accountsOnly, showAdvancedSearch } = filters;

  const handleChange = (change) => {
    const nextFilters = { ...filters, ...change };
    setFilters(nextFilters);
    onChange({
      ...nextFilters,
      keyword: toLower(trim(nextFilters.keyword)),
    });
  };

  const xl = version === 'v1' ? 2 : 3;
  return <div style={style}>
    {version === 'v1' ? null : <Row style={{ margin: '10 0', justifyContent: 'space-between' }}>
      {
        find(filters) || showAdvancedSearch
          ? <Col
            md={4} xs={4} xsStyle="margin-left: 0px;"
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              handleChange(initialFilters);
            }}
          >
            <ArrowIcon direction="left" size="large" color={colors.teal['65']} style={{ marginRight: 16 }} />
            <SupplierTitle style={{ color: colors.teal[65] }}>Back</SupplierTitle>
          </Col>
          : <Col xs={4} xsStyle="margin-left: 0px;" style={{ display: 'flex', alignItems: 'center' }}>
            <SupplierTitle>Suppliers</SupplierTitle>
          </Col>
      }
      <KeywordsInput
        showAdvancedSearch={showAdvancedSearch}
        onToggleSearchClick={() => {
          handleChange({ showAdvancedSearch: !showAdvancedSearch });
        }}
        value={keyword}
        onChange={e => handleChange({ keyword: e.target.value })}
      />
      {hasCreateUpstreamAccount && <Button
        style={{ alignSelf: 'center' }} size="large"
        onClick={(e) => {e.preventDefault(); onCreateAddSupplierPopup(true);}}
      >
        New Supplier
      </Button>}
    </Row>}
    {version === 'v2' && !showAdvancedSearch ? null : <Row style={{
      ...(version === 'v1' ? {} : { background: colors.teal['20'], borderRadius: '8px 0 8px 8px', }),
      placeContent: 'flex-start',
      marginBottom: 18, padding: '8px',
    }}>
      {version === 'v1' && <Col padded xl={xl} sm={4} xs={6}>
        <Label>Name</Label>
        <Input
          type='text'
          placeholder='Search by supplier name'
          value={keyword}
          onChange={e => handleChange({ keyword: e.target.value })}
        />
      </Col>}
      <Col padded xl={xl} sm={4} xs={6}>
        <LabeledSelect
          label="Tag"
          options={tags}
          value={find(tags, { value: tagId })}
          onChange={e => handleChange({ tagId: e.value })}
          menuShouldScrollIntoView={false}
          placeholder="Tag"
        />
      </Col>
      <Col padded xl={xl} sm={4} xs={6}>
        <LabeledSelect
          label="Status"
          options={accountStatuses}
          value={find(accountStatuses, { value: accountStatusId })}
          onChange={e => handleChange({ accountStatusId: e.value })}
          menuShouldScrollIntoView={false}
          placeholder="Status"
        />
      </Col>
      <Col padded xl={xl} sm={4} xs={6}>
        <LabeledSelect
          label="Benefit"
          options={benefits}
          value={find(benefits, { value: benefitId })}
          onChange={e => handleChange({ benefitId: e.value })}
          menuShouldScrollIntoView={false}
          placeholder="Status"
        />
      </Col>
      <Col padded xl={xl} sm={4} xs={6}>
        {
          version === 'v2'
            ? <Toggle
              size="medium" style={{ marginTop: 14, cursor: 'pointer' }}
              onClick={e => handleChange({ accountsOnly: !accountsOnly })}
            >
              <ToggleLink selected={!accountsOnly} style={{ fontWeight: 800 }}>All</ToggleLink>
              <ToggleLink selected={accountsOnly} style={{ fontWeight: 800 }}>Accounts Only</ToggleLink>
            </Toggle>
            : <div style={{ marginTop: '35px' }}>
              <LabeledCheckbox
                label="Accounts only"
                checked={accountsOnly}
                onChange={e => handleChange({ accountsOnly: !accountsOnly })}
              />
            </div>
        }
      </Col>
    </Row>}
  </div>;
};

export default SuppliersSearch;