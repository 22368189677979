import { get, find, omit, orderBy } from 'lodash';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useVirtualizer } from '@tanstack/react-virtual';

import { Theme, ConnectedPlusIcon, CommunityIcon, ConnectedIcon, Button, Loading, colors } from '@commonsku/styles';
import styled from 'styled-components';
import { document } from '../global';
import { createGlobalStyle } from './helpers';
import { handleClickCreateAccount, SupplierRow, divisionSort } from './SuppliersView';
import SuppliersSearch from './suppliers/SuppliersSearch';
import SuppliersFeatureTabs from './suppliers/SuppliersFeatureTabs';
import { useSearchSuppliers } from './suppliers/hooks';
import SupplierAvatar from './suppliers/SupplierAvatar';
import { useHasCapabilities } from '../hooks';
import DivisionSidepanel from './DivisionSidepanel';

const MainContentContainer = styled.div`
  &&&& {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media only screen and (min-width: 40em) {
      padding-left: calc(176px + 6rem);
      padding-right: 6rem;
    }

    @media only screen and (max-width: 40em) {
      padding: 0 3rem;
    }
  }  
`;

const SupplierList = ({ loading, suppliers, render }) => {
  const parentRef = React.useRef(null);
  const virtualizer = useVirtualizer({
    count: suppliers.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 55,
  });
  if (loading) return <Loading />;
  if (suppliers.length === 0) return <div style={{ textAlign: 'center' }}>
    There are no suppliers matching your criteria.
  </div>;
  const items = virtualizer.getVirtualItems();
  return <div ref={parentRef} style={{ flex: '1 1 0', overflow: 'auto' }}>
    <div style={{ height: virtualizer.getTotalSize(), width: 750, margin: '0 auto', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', transform: `translateY(${items[0].start}px)` }}>
        {items.map((virtualRow) => (
          <div
            key={virtualRow.key}
            data-index={virtualRow.index}
            ref={virtualizer.measureElement}
            style={{ borderBottom: `1px solid ${colors.neutrals['40']}` }}
          >
            {render(virtualRow)}
          </div>
        ))}
      </div>
    </div>
  </div>;
};

const SuppliersView2 = ({ style, onCreateAddSupplierPopup }) => {
  const [filters, setFilters] = useState({});
  const { suppliers, loading } = useSearchSuppliers(filters);
  const [selectedSupplierId, setSelectedSupplierId] = useState('');
  const [selectedDivisionId, setSelectedDivisionId] = useState('');
  const hasCreateUpstreamAccount = useHasCapabilities(['CREATE-UPSTREAM-ACCOUNT']);

  const root = document.getElementById('root');

  const changeSelectedSupplierId = (newSupplierId) => {
    if (newSupplierId !== selectedSupplierId) {
      setSelectedSupplierId(newSupplierId);
      const supplier = find(suppliers, { supplier_id: newSupplierId });
      if (supplier) {
        const divisions = supplier.divisions.slice();
        divisions.sort(divisionSort);
        setSelectedDivisionId(divisions[0].division_id);
      } else {
        setSelectedDivisionId('');
      }
    }
  };
  return <MainContentContainer className="main-content csku-styles" style={{
    marginTop: 45,
    ...style,
  }}>
    <SuppliersSearch
      style={{ flex: '0 0 auto' }} version="v2"
      onCreateAddSupplierPopup={onCreateAddSupplierPopup}
      onChange={(newFilters) => {
        setFilters(newFilters);
        setSelectedSupplierId('');
        setSelectedDivisionId('');
      }}
    />
    {find(filters)
      ? <SupplierList
        loading={loading} suppliers={suppliers}
        render={({ index }) => {
          const supplier = suppliers[index];
          const Icon = get({
            COMMUNITYBASIC: CommunityIcon,
            COMMUNITYPLUS: CommunityIcon,
            CONNECTEDBASIC: ConnectedIcon,
            CONNECTEDPLUS: ConnectedPlusIcon,
          }, supplier.feature_name);
          return <SupplierRow
            style={{ width: 700 }}
            key={supplier.supplier_id}
            supplier={supplier}
            showDivisions={find(omit(filters, 'showAdvancedSearch'))}
            selectedSupplierId={selectedSupplierId}
            selectedDivisionId={selectedDivisionId}
            onSelectSupplier={changeSelectedSupplierId}
            onSelectDivision={setSelectedDivisionId}
            renderSupplierRow={({ selected, onClickSupplier }) => {
              return <div className={selected ? 'selected' : ''} onClick={onClickSupplier} style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 50
              }}>
                <div style={{ flex: '0 0 64px' }}>
                  <SupplierAvatar style={{ width: 32, marginLeft: 10 }} avatar={supplier.avatar} />
                </div>
                <div style={{ flex: '1 1 0', margin: '0 10px' }}><strong><a style={{ color: '#5CA3B6' }}>
                  {supplier.supplier_name} {supplier.supplier_parent_row && <span style={{ color: '#fa237c' }}>
                    {selected ? <>&#65085;</> : <>&#65086;</>}
                  </span>}
                </a></strong></div>
                <div style={{ flex: '0 0 50px' }}>{Icon ? <Icon size="large" /> : null}</div>
                <div style={{ flex: '0 0 160px', textAlign: 'center' }}>
                  {
                    (!supplier.supplier_parent_row && !supplier.supplier_account_id) && hasCreateUpstreamAccount &&
                    <Button size="small" onClick={handleClickCreateAccount(supplier)}>Create Account</Button>
                  }
                </div>
              </div>;
            }}
            renderDivisionRow={({ division, onClick }) => {
              const selected = selectedDivisionId === division.division_id;
              return <div key={division.division_id} onClick={onClick} style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 50
              }}>
                <div style={{ flex: '1 1 0', marginLeft: 74, fontSize: '0.8rem' }}>
                  <a style={selected ? { color: '#BF3F69', fontWeight: 'bold' } : {}}>
                    {division.division_name}
                  </a>
                </div>
                <div style={{ flex: '0 0 160px', textAlign: 'center' }}>
                  {
                    !division.supplier_account_id && hasCreateUpstreamAccount &&
                    <Button size="small" onClick={handleClickCreateAccount(division)}>Create Account</Button>
                  }
                </div>
              </div>;
            }}
          />;
        }}
      />
      : <SuppliersFeatureTabs
        style={{ flex: 1, overflow: 'hidden' }}
        suppliers={orderBy(suppliers, [supplier => supplier.supplier_name.toLowerCase()])}
        onSelectSupplier={(supplier) => {
          changeSelectedSupplierId(selectedSupplierId === supplier.supplier_id ? '' : supplier.supplier_id);
        }}
      />
    }
    {ReactDOM.createPortal(
      <DivisionSidepanel
        supplier={find(suppliers, { supplier_id: selectedSupplierId })}
        division_id={selectedDivisionId}
        onChangeDivision={d => {
          setSelectedDivisionId(get(d, 'division_id', ''));
        }}
        onClose={() => {
          setSelectedSupplierId('');
          setSelectedDivisionId('');
        }}
      />,
      root
    )}
  </MainContentContainer>;
};

const GlobalStyle = createGlobalStyle();

const SuppliersView2Container = (props) => {
  return <Theme>
    <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }} />
    <SuppliersView2 {...props} />
  </Theme>;
};

export default SuppliersView2Container;
