import { filter } from 'lodash';
import React, { useState, useRef } from 'react';
import { Col, TabBar, Tab, H3, ConnectedPlusIcon, CommunityIcon, ConnectedIcon } from '@commonsku/styles';
import styled from 'styled-components';

import SupplierAvatar from './SupplierAvatar';

const TabText = styled(H3)`
  &&& {
    font-family: "skufont-bold",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0;
  }
`;

const SuppliersFeatureTabs = ({ style, suppliers, onSelectSupplier }) => {
  const ref = useRef(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [scrollShadow, setScrollShadow] = useState(false);
  const imgStyle = { width: 64, height: 64, marginRight: '1rem' };

  function onScroll() {
    setScrollShadow(ref.current.scrollTop !== 0);
  }

  const tabs = [
    {
      label: <><ConnectedPlusIcon style={imgStyle} altText='Connected+ Logo' /><TabText > Connected +</TabText ></>,
      predicate: (supplier) => 'CONNECTEDPLUS' === supplier.feature_name,
    },
    {
      label: <><ConnectedIcon style={imgStyle} altText='Connected Logo' /><TabText > Connected</TabText ></>,
      predicate: (supplier) => 'CONNECTEDBASIC' === supplier.feature_name,
    },
    {
      label: <><CommunityIcon style={imgStyle} altText='Community Logo' /><TabText > Community</TabText ></>,
      predicate: (supplier) => ['COMMUNITYBASIC', 'COMMUNITYPLUS'].indexOf(supplier.feature_name) > -1,
    },
  ];
  return <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 0, ...style }}>
    <TabBar style={{
      display: 'flex', flex: '0 0 auto', margin: 0, justifyContent: 'space-evenly',
      boxShadow: scrollShadow ? '0 10px 10px -12px rgb(0 0 0 / 50%)' : 'none', zIndex: 10
    }}>
      {tabs.map((tab, index) => <Tab
        key={'tabs_' + index} size={tabs.length}
        selected={index === selectedTabIndex}
        onClick={() => setSelectedTabIndex(index)}
        style={{
          borderBottom: index === selectedTabIndex ? '8px solid #db0057' : '',
          textAlign: 'center', width: '33.3%', marginRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
        {tab.label}
      </Tab>)}
    </TabBar>
    <div ref={ref} style={{
      display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start',
      alignContent: 'flex-start', overflow: 'auto', paddingTop: 24
    }} onScroll={onScroll}>
      {filter(suppliers, tabs[selectedTabIndex].predicate).map(s => <Col
        key={s.supplier_id}
        totalCols={s.feature_name === 'CONNECTEDPLUS' ? 4 : 5} xs={1} padded
      >
        <div
          style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          onClick={() => onSelectSupplier(s)}
        >
          <SupplierAvatar avatar={s.avatar} size={s.feature_name === 'CONNECTEDPLUS' ? 'large' : 'medium'} />
        </div>
      </Col>)}
    </div>
  </div>;
};

export default SuppliersFeatureTabs;
