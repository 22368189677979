import { find, map } from 'lodash';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { Button } from '@commonsku/styles';
import { getImageSrc, oauth } from '../utils';
import { window, document } from '../global';
import DivisionSidepanel from './DivisionSidepanel';
import Badge from './Badge';
import { hasBenefits, hasSupplierBenefits } from './suppliers/utils';
import { useSearchSuppliers } from './suppliers/hooks';
import SuppliersSearch from './suppliers/SuppliersSearch';
import { useHasCapabilities } from '../hooks';

const PS_LEVELS = {
  'yes': 2,
  'unavailable': 1,
  'no': 0
};
const PS_LEVEL_INVERSE = ['no', 'unavailable', 'yes'];

export const getPSConnectedLevel = division => ['CONNECTED', 'INSIGHTS', 'COLLABORATE', 'CONNECTEDBASIC', 'CONNECTEDPLUS'].includes(division.feature_name)
  ? (
    (division.order_status_service === 'yes' || division.epo_service === 'yes' || division.shipment_service === 'yes')
      ? 'yes'
      : ((division.order_status_service === 'unavailable' || division.epo_service === 'unavailable' || division.shipment_service === 'unavailable') ? 'unavailable' : 'no')
  )
  : 'no'
;

const getSupplierPSConnectedLevel = supplier => PS_LEVEL_INVERSE[supplier.divisions.reduce((l, d) => Math.max(l, PS_LEVELS[getPSConnectedLevel(d)]), 0)];

export const scoreDivision = d => {
  let score = 0;
  if (hasBenefits(d)) {
    score += 8;
  }
  if (d.epo_service !== 'no') {
    score += 4;
  }
  if (d.order_status_service !== 'no') {
    score += 2;
  }
  if (d.inventory_service !== 'no') {
    score += 1;
  }
  return score;
};

export const divisionSort = (a, b) => {
  const aScore = scoreDivision(a);
  const bScore = scoreDivision(b);
  if (aScore !== bScore) {
    return bScore - aScore;
  }
  return a.division_name.localeCompare(b.division_name);
};

const DEFAULT_SUPPLIER_AVATAR = '/images/supplier-avatar-64x64.png';
const SupplierAvatar = ({ avatar }) => <img className="avatar" src={getImageSrc(avatar, 'small', { defaultImage: DEFAULT_SUPPLIER_AVATAR })} />;

export const EmptyRow = () => (
  <tr>
    <td colSpan={5} style={{ textAlign: 'center' }}>
      <p>There are no suppliers matching your criteria.</p>
    </td>
  </tr>
);

export const LoadingRow = () => (
  <tr>
    <td colSpan={5} style={{ textAlign: 'center' }}>
      <img src="/images/gears.gif" />
    </td>
  </tr>
);

const HeaderRow = () => (
  <tr className="cursor-default" style={{ background: '#EDF2F5' }}>
    <td colSpan={2} width="50%" className="table-cell-sticky"></td>
    <td width="20%" className="table-cell-sticky badge-col">Connected</td>
    <td width="20%" className="table-cell-sticky badge-col">Benefits</td>
    <td width="10%" className="table-cell-sticky badge-col"></td>
  </tr>
);

export const SupplierRow = ({
  supplier, onSelectSupplier, onSelectDivision, selectedSupplierId, selectedDivisionId,
  showDivisions = false, autoSelectDivision = false, renderSupplierRow, renderDivisionRow
}) => {
  const hasCreateUpstreamAccount = useHasCapabilities(['CREATE-UPSTREAM-ACCOUNT']);
  const selected = selectedSupplierId === supplier.supplier_id;
  const onClickSupplier = () => {
    if (selectedSupplierId === supplier.supplier_id) {
      onSelectSupplier('');
      onSelectDivision('');
    } else {
      onSelectSupplier(supplier.supplier_id);
      if (!supplier.supplier_parent_row) {
        onSelectDivision(supplier.division_id);
      } else if (autoSelectDivision) {
        const divisions = supplier.divisions.slice();
        divisions.sort(divisionSort);
        onSelectDivision(divisions[0].division_id);
      }
    }
  };
  return (
    <>
      {
        renderSupplierRow
          ? renderSupplierRow({ selected, onClickSupplier })
          : <tr className={selected ? 'selected' : ''} onClick={onClickSupplier}>
            <td className="avatar"><SupplierAvatar avatar={supplier.avatar} /></td>
            <td><strong><a style={{ color: '#5CA3B6' }}>{supplier.supplier_name} {supplier.supplier_parent_row && (selected ? <span style={{ color: '#fa237c' }}>&#65085;</span> : <span style={{ color: '#fa237c' }}>&#65086;</span>)}</a></strong></td>
            <td className="badge-col"><Badge type="connected" service_level={getSupplierPSConnectedLevel(supplier)} style={{ padding: 0 }} /></td>
            <td className="badge-col"><Badge type="benefits" service_level={hasSupplierBenefits(supplier) ? 'yes' : 'no'} style={{ padding: 0 }} /></td>
            <td className="badge-col">
              {
                (!supplier.supplier_parent_row && !supplier.supplier_account_id) &&
                hasCreateUpstreamAccount &&
                <Button size="tiny" onClick={handleClickCreateAccount(supplier)}>Create Account</Button>
              }
            </td>
          </tr>
      }
      {(showDivisions || selected) && supplier.supplier_parent_row && map(supplier.divisions, d => {
        const onClick = () => {
          if (selectedDivisionId === d.division_id) {
            onSelectSupplier('');
            onSelectDivision('');
          } else {
            onSelectSupplier(supplier.supplier_id);
            onSelectDivision(d.division_id);
          }
        };
        return renderDivisionRow ? renderDivisionRow({ division: d, onClick }) : <DivisionRow
          key={d.division_id} division={d} selected={selectedDivisionId === d.division_id}
          onClick={onClick}
        />;
      })}
    </>
  );
};

export const DivisionRow = ({ division, onClick, selected = false }) => {
  const hasCreateUpstreamAccount = useHasCapabilities(['CREATE-UPSTREAM-ACCOUNT']);
  return (
    <tr className={'child' + (selected ? ' selected' : '')} onClick={onClick}>
      <td className="avatar">&nbsp;</td>
      <td><strong><a>{division.division_name}</a></strong></td>
      <td className="badge-col">
        <Badge type="connected" service_level={getPSConnectedLevel(division)} style={{ padding: 0 }} />
      </td>
      <td className="badge-col">
        <Badge type="benefits" service_level={hasBenefits(division) ? 'yes' : 'no'} style={{ padding: 0 }} />
      </td>
      <td className="badge-col">
        {
          !division.supplier_account_id &&
          hasCreateUpstreamAccount &&
          <Button size="tiny" onClick={handleClickCreateAccount(division)}>Create Account</Button>
        }
      </td>
    </tr>
  );
};

export const handleClickCreateAccount = supplier => e => {
  e.stopPropagation();
  const data = {
    company_name: supplier.supplier_name,
    company_id: supplier.supplier_id,
    division_id: supplier.division_id,
    company_type: 'SUPPLIER'
  };
  oauth('POST', 'account', data).then(({ json }) => {
    window.location.href = `vendor.php?id=${json.account.company_id}&division_id=${json.account.division_id}`;
  });
};

const Suppliers = ({ onCreateAddSupplierPopup }) => {
  const [filters, setFilters] = useState({});
  const { suppliers, loading } = useSearchSuppliers(filters);
  const [selectedSupplierId, setSelectedSupplierId] = useState('');
  const [selectedDivisionId, setSelectedDivisionId] = useState('');
  const hasCreateUpstreamAccount = useHasCapabilities(['CREATE-UPSTREAM-ACCOUNT']);

  const root = document.getElementById('root');
  return (
    <div className="main-content csku-styles" style={{ marginTop: '0rem', marginLeft: '-0.6rem' }}>
      <div className="new-action-button">
        {hasCreateUpstreamAccount && <Button onClick={(e) => {e.preventDefault(); onCreateAddSupplierPopup(true);}}>
          New Supplier
        </Button>}
      </div>
      <SuppliersSearch onChange={({ showAdvancedSearch, ...newFilters }) => {
        setFilters(newFilters);
        setSelectedSupplierId('');
        setSelectedDivisionId('');
      }}/>
      <div className="table-wrapper">
        {ReactDOM.createPortal(<DivisionSidepanel
          supplier={find(suppliers, {supplier_id: selectedSupplierId})}
          division_id={selectedDivisionId}
          onChangeDivision={d => setSelectedDivisionId(d.division_id)}
          onClose={() => setSelectedSupplierId('')}
        />, root)}
        <div className="scroll-wrapper">
          <table className="level-table">
            <thead style={{ display: 'table-header-group' }}><HeaderRow /></thead>
            <tbody>{
              loading ? <LoadingRow/> : (
                suppliers.length === 0 ? <EmptyRow /> : suppliers.map(s =>
                  <SupplierRow
                    key={s.supplier_id}
                    supplier={s}
                    showDivisions={find(filters)}
                    autoSelectDivision={true}
                    selectedSupplierId={selectedSupplierId}
                    selectedDivisionId={selectedDivisionId}
                    onSelectSupplier={setSelectedSupplierId}
                    onSelectDivision={setSelectedDivisionId}
                  />)
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
