import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, PanelTileContact, H5, Avatar, Spinner } from '@commonsku/styles';
import { Select } from './helpers';
import { getOptions } from './company-search/selectors';

import { getDepartmentOptions } from '../selectors/dropdowns';
import { getImageSrc, oauth } from '../utils';

const DEFAULT_CONTACT_AVATAR = '/images/user-avatar3-120x120.png';
const getAvatarSrc = avatar => getImageSrc(avatar, 'small', { defaultImage: DEFAULT_CONTACT_AVATAR });

const SupplierContacts = ({ division_id, departments }) => {
  const [contactLoading, setContactLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('');

  const champions = contacts.filter(c => c.is_champion == 1);

  const availableDepartmentIds = contacts.map(c => c.contact_department_id);
  const availableDepartments = departments.filter(d => availableDepartmentIds.includes(d.key));
  const departmentOptions = getOptions(
    [{ key: '', value: 'All' }].concat(champions.length > 0 ? { key: 'champions', value: 'Champions' } : []).concat(availableDepartments),
    'key',
    'value'
  );
  useEffect(() => {
    if (!!division_id) {
      setContactLoading(true);
      oauth('GET', 'contact/toc', { parent_type: 'SUPPLIER', parent_id: division_id, include_user_info: true }).then(
        ({ json }) => {
          const contacts = json.contacts.reduce((o, c) => ({ ...o, [c.contact_id]: c }), {});
          setContacts(Object.values(contacts));
          setContactLoading(false);
        }
      );
    }
  }, [division_id]);

  const handleChangeDepartment = e => setSelectedDepartmentId(e.value);
  const showChampions = champions.length > 0 && (selectedDepartmentId === '' || 'champions' === selectedDepartmentId);

  return contactLoading ?
    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}><Spinner /></div> :
    (contacts.length > 0 ? <div>
      <Row>
        <Col xs style={{ marginBottom: 16 }}>
          <Select
            options={departmentOptions}
            value={selectedDepartmentId}
            placeholder="Departments"
            onChange={handleChangeDepartment}
            className="csku-select"
          />
        </Col>
      </Row>
      <Row>
        <Col xs>
          {showChampions && <>
            <H5 underlined style={{ fontWeight: 'bold', marginTop: 'initial' }}>commonsku Champions</H5>
            <Row>
              {champions.map(c =>
                <Col key={c.contact_id} xs md={6}>
                  <PanelTileContact
                    avatar={<Avatar pic={getAvatarSrc(c.avatar)} />}
                    name={c.contact_first_name + ' ' + c.contact_last_name}
                    position={c.contact_position}
                    email={c.contact_email}
                    phone={c.primary_phone_number + ' ' + c.primary_phone_extension}
                  />
                </Col>
              )}
            </Row>
	  </>}
          {availableDepartments.filter(d => selectedDepartmentId === '' || d.key === selectedDepartmentId).map(
            (d, i) => <div key={d.key}>
              <H5 underlined style={{ fontWeight: 'bold', marginTop: i !== 0 || showChampions ? 30 : 'initial' }}>{d.value || '' }</H5>
              <Row>
                {contacts.filter(c => c.contact_department_id === d.key).map(c =>
                  <Col key={c.contact_id} xs md={6}>
                    <PanelTileContact
                      avatar={<Avatar pic={getAvatarSrc(c.avatar)} />}
                      name={c.contact_first_name + ' ' + c.contact_last_name}
                      position={c.contact_position}
                      email={c.contact_email}
                      phone={c.primary_phone_number + ' ' + c.primary_phone_extension}
                    />
                  </Col>
                )}
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </div> : <Row>
      <Col xs style={{ marginTop: 15 }}>
        <p>This supplier has no contacts</p>
      </Col>
    </Row>);
};

const mapStateToProps = (state) => ({
  departments: getDepartmentOptions(state),
});

export default connect(mapStateToProps)(SupplierContacts);
