import { find, filter, get, groupBy, pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import { memoizeDebouncedOAuthGet } from '../../utils';
import { useEffect, useState } from 'react';

const groupDivisionsBySupplierId = (suppliers) => {
  const divisions = groupBy(suppliers, 'supplier_id');
  return filter(suppliers, s => !s.supplier_child_row).map(s => {
    return {
      ...s,
      divisions: get(divisions, s.supplier_id, []),
    };
  });
};

const fetchSuppliers = memoizeDebouncedOAuthGet('page/suppliers', 700);

export const useSearchSuppliers = (filters) => {
  const { keyword, tagId, benefitId, accountStatusId, accountsOnly } = filters;
  const suppliers = useSelector((state) => get(state, 'entities.suppliers', {}));
  const [filtered, setFiltered] = useState(groupDivisionsBySupplierId(suppliers));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = {
      ...pickBy({ keyword, tag_id: tagId, benefit_id: benefitId, account_status_id: accountStatusId }),
      accounts_only: accountsOnly ? 1 : 0,
    };
    if (find(data)) {
      setLoading(true);
      fetchSuppliers({ ...data, entities_only: true })
        .then((response) => {
          setFiltered(groupDivisionsBySupplierId(get(response, 'json.payload.suppliers')));
        })
        .catch((e) => {
          console.error(e);
          setFiltered([]);
        })
        .finally(() => {
          setLoading(false);
        })
      ;
    } else {
      setFiltered(groupDivisionsBySupplierId(suppliers));
    }
  }, [keyword, tagId, benefitId, accountStatusId, accountsOnly]);

  return { suppliers: filtered, loading };
};