import React, { useEffect, useState } from 'react';

import { Row, Col, H5, Spinner } from '@commonsku/styles';
import { oauth } from '../utils';
import CollectionBadge from './CollectionBadge';

const SupplierCollections = ({ supplier }) => {

  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = {
      supplier_id: supplier.supplier_id,
      'max-results': 12,
    };
    oauth('GET', 'collection', data).then(
      ({ json }) => {
        setLoading(false);
        setCollections(json.collections);
      }
    );

  }, [supplier.supplier_id]);
  return (
    <div>
      <Row>
        <Col xs style={{ marginTop: 15 }}>
          <H5>Our Collections</H5>
        </Col>
      </Row>
      <Row>
        {loading ? <Spinner /> : <>
          {collections.length > 0 ? collections.map((c, i) => <a key={c.order_id} style={{ display: 'block', cursor: 'pointer', padding: '0.5rem' }} target="_blank" href={`/collection/${c.order_id}`} className={'small-6' + (i === collections.length - 1 ? ' end' : '')}>
            <CollectionBadge collection={c} showSupplierDetails={false} />
          </a>) : <p>No available collections.</p>}
        </>}
      </Row>
    </div>
  );
};

export default SupplierCollections;
