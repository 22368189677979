export const hasBenefits = division => (division.benefits || []).length > 0;
export const hasSupplierBenefits = supplier => supplier.divisions.some(d => hasBenefits(d));

const isConnected = division => ['CONNECTED', 'CONNECTEDBASIC', 'CONNECTEDPLUS'].includes(division.feature_name);
export const isSupplierConnected = supplier => supplier.divisions.some(d => isConnected(d));

const scoreSupplier = s => {
  let score = 0;
  if (isSupplierConnected(s)) {
    score += 64;
  }
  if (hasSupplierBenefits(s)) {
    score += 32;
  }
  if (s.epo_service !== 'no') {
    score += 16;
  }
  if (s.order_status_service !== 'no') {
    score += 8;
  }
  if (s.inventory_service !== 'no') {
    score += 4;
  }
  if (s.public == 1 && ['INSIGHTS', 'COMMUNITYPLUS'].includes(s.feature_name)) {
    score += 2;
  }
  if (s.public == 1 && ['COLLABORATE', 'COMMUNITYBASIC'].includes(s.feature_name)) {
    score += 1;
  }
  return score;
};

export const supplierSort = (a, b) => {
  const aScore = scoreSupplier(a);
  const bScore = scoreSupplier(b);
  if (aScore !== bScore) {
    return bScore - aScore;
  }
  return a.supplier_name.localeCompare(b.supplier_name);
};
