import { get, find, isEmpty } from 'lodash';
import React from 'react';
import { Row, Col, Button, SidePanel, H2, Tabs } from '@commonsku/styles';

import { oauth } from '../utils';
import { Select } from './helpers';
import SupplierBenefits from './SupplierBenefits';
import SupplierContacts from './SupplierContacts';
import SupplierCollections from './SupplierCollections';
import { useHasCapabilities } from '../hooks';

function hasUser(s) {
    return s.feature_name === 'INSIGHTS' || s.feature_name === 'COLLABORATE' || s.feature_name === 'CONNECTED' || s.feature_name === 'COMMUNITYBASIC' || s.feature_name === 'COMMUNITYPLUS' || s.feature_name === 'CONNECTEDBASIC' || s.feature_name === 'CONNECTEDPLUS';
}

function isServiceUnavailable(s) {
  return [s.inventory_service, s.order_status_service, s.shipment_service].indexOf("unavailable") > -1;
}

function createAccount(s) {
  var data = { company_name: s.supplier_name, company_id: s.supplier_id, division_id: s.division_id, company_type: "SUPPLIER" };
  oauth('POST', 'account', data).then((response) => {
    window.location.href = 'vendor.php?id=' + response.json.account.company_id + '&division_id=' + response.json.account.division_id;
  });
}

const AccountButton = ({ division }) => {
  const hasCreateUpstreamAccount = useHasCapabilities(['CREATE-UPSTREAM-ACCOUNT']);
  if (!division) {
    return null;
  }
  if (division.supplier_account_id == null) {
    return !hasCreateUpstreamAccount ? null : <Button
      style={{ marginRight: 10, lineHeight: 'normal' }}
      onClick={e => { e.stopPropagation(); createAccount(division); }}
    >Create Account</Button>;
  } else if (hasUser(division)) {
    if (isServiceUnavailable(division)) {
      return <Button style={{ display: 'none', marginRight: 10, lineHeight: 'normal' }}>Enable</Button>;
    }
    return null;
  } else {
    return <Button style={{ display: 'none', marginRight: 10, lineHeight: 'normal' }}>Invite</Button>;
  }
};

function DivisionSidepanel({
  supplier,
  onChangeDivision,
  onClose,
  division_id,
}) {
  const divisions = get(supplier, 'divisions') || [];
  const division = find(divisions, { division_id: division_id });
  if (!division) {
    return null;
  }

  const tabs = [
    { label: 'Contacts', content: <SupplierContacts division_id={division_id} /> }
  ];

  if (!isEmpty(get(division, 'benefits'))) {
    tabs.splice(0, 0, { label: 'Benefits', content: <SupplierBenefits supplier={division} /> });
  }
    if (division && ['CONNECTED', 'INSIGHTS', 'COMMUNITYBASIC', 'COMMUNITYPLUS', 'CONNECTEDBASIC', 'CONNECTEDPLUS'].includes(division.feature_name)) {
    tabs.push({ label: 'Collections', content: <SupplierCollections supplier={division} /> });
  }

  return (
    <SidePanel
      style={{ padding: '1em', width: '600px', paddingBottom: 15 }}
      visible={division}
      header={
        <Row>
          <Col xs style={{ textAlign: 'right', }}>
            <Button
              cta
              style={{
                marginRight: 10,
                lineHeight: 'normal',
                // ...(supplier && supplier.supplier_account_id == null ? {width: '100%', marginBottom: 10} : {})
              }}
              onClick={() => {
                if (division) {
                  window.open(`/vendor.php?id=${division.supplier_id}&division_id=${division.division_id}`, '_blank');
                }
              }}
            >Supplier Page</Button>
            <AccountButton division={division} />
            <Button
              // sm={'width: fit-content; margin-top: 0;'}
              // xs={'width: 100%;'}
              style={{ lineHeight: 'normal' }}
              onClick={(e) => {
                onClose && onClose(e);
              }}
            >Close</Button>
          </Col>
          <Col xs>
            <H2 style={{
              marginTop: '1.5rem',
              marginBottom: '1.5rem',
            }}>{divisions.length > 1 ? division.supplier_name : division.division_name}</H2>
            {divisions.length > 1 && <div style={{
              background: '#E1F7FA',
              padding: '13px',
              borderRadius: '8px',
            }}>
              <Select
                options={divisions.map(d => ({ value: d.division_id, label: d.division_name }))}
                value={division_id}
                onChange={option => onChangeDivision(divisions.filter(d => d.division_id === option.value)[0])}
              />
            </div>}
          </Col>
        </Row>
      }
    >
      {tabs.length > 1 ? <Tabs padded tabs={tabs} /> : tabs[0].content}
    </SidePanel>
  );
}

export default DivisionSidepanel;
